<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="mt-8">
                        <v-app-bar color="teal" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Account settings</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <!--
                            <p class="text-overline mb-0 mt-0">Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all users</router-link>
                            </p>
                            -->

                            <p class="text-overline mb-0 mt-0">API Tokens</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-client', params: { accountId: this.$route.params.accountId } }">See all clients</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.client_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-client-token', params: { accountId: this.$route.params.accountId } }">See all API tokens</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.client_token_count }})</span>
                            </p>

                            <!-- <AccountViewList :list="list"/> -->
                            <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->
                            <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/account-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        accountSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            account: (state) => state.account,
        }),
    },
    methods: {
        async loadAccountSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'summary' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountSummary: false });
            }
        },
    },
    mounted() {
        this.loadAccountSummary();
    },
};
</script>
